<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card :elevation="isMobile ? '1' : '0'" :width="isMobile ? '350' : '450'" height="480"
                class="mt-md-16 md-mx-0 mx-auto" style="border: 1px solid #57430E">
          <v-card-text>
            <div class="my-6 mx-auto text-center">
              <v-img class="mx-auto" contain width="100" height="100" :src="logo"/>
            </div>
            <v-form v-model="form" @submit.prevent="onSubmit">
              <v-text-field v-model="rec.username" variant="outlined" color="secondary" density="comfortable" placeholder="Enter Your ID"
                            prepend-inner-icon="mdi-mail"/>
              <div class="pt-16">
                <v-btn :loading="loading" :disabled="!rec.username" type="submit" elevation="0"
                       color="primaryDark" style="color: white;" width="100%" height="4em">
                  Proceed
                  <v-icon end size="32">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="12" md="12" class="py-5">
                <p class="text-center primary--text" style="cursor: pointer" v-on:click="$emit('navigate', 0)">
                  Go back
                </p>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>

</template>


<script>
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import MessageBox from "@ui/components/MessageBox.vue";

export default {
  name: 'ForgotPassword',
  props: ["logo"],
  components: {MessageBox},
  data: () => ({
    loading: false,
    form: false,
    messageShow: false,
    messageType: '',
    message: '',
    rec: {
      username: null
    }
  }),

  methods: {
    onSubmit() {
      this.sendOtp()
    },

    sendOtp(){
      this.loading = true
      Api.auth.sendOtp(this.rec)
          .then(res => {
            if(res.data.success){
              this.message = "OTP has been sent"
              this.messageType = 'success'
              this.messageShow = true
              this.$emit('update-record', this.rec.username)
              this.$emit('navigate', 2)
            } else{
              this.message = "Failed to send OTP"
              this.messageType = 'error'
              this.messageShow = true
            }
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => {
            this.loading = false
          })
    },
  },


  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
}
</script>



