export default {
    emailRules: [
        v => !!v || 'This field is required',
        v => /.+@.+([.])+.+/.test(v) || 'E-mail must be valid'
    ],

    textRules: [v => !!v || 'This field is required'],

    numericRules: [
        v => /^[0123456789]+$/.test(v)  || 'Invalid data'
    ],

    nonZeroNumbers: [
        v => /^[123456789]+$/.test(v)  || 'Invalid data'
    ],

    decimalsRules: [
        v => !!v || 'This field is required',
        v => /^\d+(\.\d{0,8})?$/.test(v)  || 'This field must be numeric'
    ],

    passwordRules: [ 
        v => !!v || 'Password is required', 
        v => (v && v.length >= 8) || 'Password must have at least 8 characters',
        // v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character', 
        // v => /(?=.*\d)/.test(v) || 'Must have one number', 
        // v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]' 
    ]
}