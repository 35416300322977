<template>
  <v-text-field outlined type="text" maxlength="1" tabindex="1" v-on="$listeners" v-bind="$attrs" :value="value" @input="$emit('input', $event)"/>
</template>

<script>

export default {
  name: 'CodeInput',
  props: ['value'],
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
@media (min-device-width : 962px) {
  input {
    width: 54px;
    height: 54px;
    box-sizing: border-box;
    text-align: center;
    border: 2.4px solid rgb(52, 47, 47);
    /* outline: 0; */
    appearance: none;
    font-size: 1.2rem;
    outline-color: red;

  }
//input {
//  width: 54px;
//  height: 54px;
//  box-sizing: border-box;
//  text-align: center;
//  border: 2.4px solid rgb(52, 47, 47);
//  /* outline: 0; */
//  appearance: none;
//  font-size: 2rem;
//  outline-color: red;

}

@media (max-device-width : 961px) {
  input {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    text-align: center;
    border: 2.4px solid rgb(52, 47, 47);
    /* outline: 0; */
    appearance: none;
    font-size: 1.2rem;
    outline-color: red;

  }
}

input:hover {
  border-color: #AE8B31;
}
</style>
