<template>
  <transition name="slide" duration="550">
    <x-dialog v-if="!isMobile" :persistent="true" :show="show" maxwidth="480" scrollable :transition="null">
      <x-card>
        <x-card-title class="py-0 px-2 my-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="d-flex py-2" color="error" @click="show = !show">mdi-close</v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </x-card-title>

        <v-divider style="background-color: gray"/>

        <div class="mx-2 mx-md-0" style="word-wrap: break-word; white-space: normal">
          <v-expansion-panels flat class="px-1 mx-0">
            <v-expansion-panel>
              <v-expansion-panel-header class="" expand-icon="mdi-menu-down">
                <div class="font-weight-bold">Registration Guidelines for Fresh Undergraduate Students (100L & DE) <a href="https://my.fulafia.edu.ng/api/default-document/get-document?programme_type=2&name=registration_procedure">Download</a> </div>
              </v-expansion-panel-header>
              <v-divider class="my-1"></v-divider>
              <v-expansion-panel-content class="">
                <div style="font-size: 10pt">
                  <div class="font-weight-bold">First semester</div>
                  <div >
                    <ol>
                      <li>Login to the Portal</li>
                      <li>Click Apply, Create account and verify your Jamb registration number</li>
                      <li>Login with the email and password used @ STEP 1 to generate Applicant ID</li>
                      <li>Copy the Applicant ID and use it as username to log in</li>
                      <li>Update your passport Photo</li>
                      <li>Click on “ADMISSION OPTION” Button to Accept your Admission</li>
                      <li>Click Accept Admission to pay Admission fee</li>
                      <li>Click on “ADMISSION OPTION” select "Generate/Print invoice" Button to Download your receipt</li>
                      <li>Click on PAY SCHOOL FEES, update your bio-data</li>
                      <li>Click on FEES and PAY.</li>
                      <li>On successful payment of school fees, a pop up message with your email address and password will come up</li>
                      <li>Log in to your email account to access your matric number</li>
                      <li>Proceed to pay your other charges such as faculty, departmental etc</li>
                      <li>Proceed with your course registration</li>
                      <li>Go back to dashboard, click on Course Registration and then on “Semester” then “First” to register for First Semester Courses</li>
                      <li>Register for your courses. </li>
                      <li>Take the printed course registration form to your Level Adviser for endorsement.</li>
                      <li>Visit your department (All Students), faculty (All Students) and college (Medical Students), and submit the first Semester CRF.</li>
                      <li>Visit the academic affairs and submit all the first Semester CRF</li>
                    </ol>
                  </div>
                  <div class="font-weight-bold font-italic my-3">All payments must be made on the portal. Failure to register at the stipulated time or adhere to the guidelines would attract a penalty of 10,000 NGN
                    Should you encounter any technical difficulties, please send them to mailto:help@fulafia.edu.ng and expect reply/resolution minimum 48hrs.</div>
                  <div class="font-weight-bold">Second semester</div>
                  <div >
                    <ol>
                      <li>Login to the Portal</li>
                      <li>Login with the Matric Number and password</li>
                      <li>Go to the dashboard, click on Course Registration and then on “Semester” then “Second” to register for Second Semester Courses</li>
                      <li>Register for your courses</li>
                      <li>Take the printed course registration form to your Level Adviser for endorsement.</li>
                      <li>Visit your department (All Students), faculty (All Students) and college (Medical Students), and submit the second Semester CRF</li>
                      <li>Visit the academic affairs and submit all the second Semester CRF.</li>
                    </ol>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="" expand-icon="mdi-menu-down">
                <div class="font-weight-bold">Announcement on Matriculation </div>
              </v-expansion-panel-header>
              <v-divider class="my-1"></v-divider>
              <v-expansion-panel-content class="">
                <div style="font-size: 10pt">
                  <p>The matriculation and orientation ceremonies of new students as scheduled in the reviewed time-table has been postponed indefinitely</p>
                  <p>The new 100level students admitted 2022/2023 (those that wrote jamb in 2022 for which their admission is being processed now) can not be on campus now but can continue their registration and process payments on-line</p>
                  <p>Accordingly, resumption date for 2021/2022 is 17th April, 2023 and 2022/2023 set is to be announced</p>

                  <div class="mt-4 font-weight-bold">Signed:</div>
                  <div class=" mb-4 font-weight-bold font-italic">Management</div>
                  <p>Following the announcement made by the University asking the 100-Level students of 2022/2023 session to continue with their registration and process payments online, management wishes to make further clarification as follows:</p>
                  <p>The decision of the University was made to reduce traffic in the process of registration of two sets of students concurrently. </p>
                  <p>Therefore, based on the decision made by the University, students of 2021/2022 session will now have to complete their registration and resume classes while their counterparts of the 2022/2023 session will be engaged in an online registration process and classes.</p>
                  <p>Those among the 2022/2023 set  who are presently in Lafia and have secured their accommodation, an arrangement has been made to take care of their online classes. Modalities for online classes for the first semester will be communicated to the affected students soon.</p>
                  <p>It shall be noted that the students (2022/2023 set) will resume on campus at the end of May 2023 and be merged in the second semester with their 2021/2022 colleagues to continue with their studies concurrently.</p>
                  <p>Consequently, orientation and matriculation exercises for both sets of students will, also, hold at the same time.</p>
                  <p>It should be noted that Federal University of Lafia is reputed for its meticulous and diligent way of doing things, a situation which has always turned out to be in the overall interest of the University community.</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </x-card>
    </x-dialog>

    <x-dialog :show="show" maxwidth="480" scrollable :transition="'dialog-bottom-transition'" v-else>
      <x-card v-if="show">
        <v-card-text class="px-0">
          <v-expansion-panels flat class="px-1 mx-0">
            <v-expansion-panel>
              <v-expansion-panel-header class="" expand-icon="mdi-menu-down">
                <div class="font-weight-bold">Registration Guidelines for Fresh Undergraduate Students (100L & DE) <a href="https://my.fulafia.edu.ng/api/default-document/get-document?programme_type=2&name=registration_procedure">Download</a> </div>
              </v-expansion-panel-header>
              <v-divider class="my-1"></v-divider>
              <v-expansion-panel-content class="">
                <div style="font-size: 10pt">
                  <div class="font-weight-bold">First semester</div>
                  <div >
                    <ol>
                      <li>Login to the Portal</li>
                      <li>Click Apply, Create account and verify your Jamb registration number</li>
                      <li>Login with the email and password used @ STEP 1 to generate Applicant ID</li>
                      <li>Copy the Applicant ID and use it as username to log in</li>
                      <li>Update your passport Photo</li>
                      <li>Click on “ADMISSION OPTION” Button to Accept your Admission</li>
                      <li>Click Accept Admission to pay Admission fee</li>
                      <li>Click on “ADMISSION OPTION” select "Generate/Print invoice" Button to Download your receipt</li>
                      <li>Click on PAY SCHOOL FEES, update your bio-data</li>
                      <li>Click on FEES and PAY.</li>
                      <li>On successful payment of school fees, a pop up message with your email address and password will come up</li>
                      <li>Log in to your email account to access your matric number</li>
                      <li>Proceed to pay your other charges such as faculty, departmental etc</li>
                      <li>Proceed with your course registration</li>
                      <li>Go back to dashboard, click on Course Registration and then on “Semester” then “First” to register for First Semester Courses</li>
                      <li>Register for your courses. </li>
                      <li>Take the printed course registration form to your Level Adviser for endorsement.</li>
                      <li>Visit your department (All Students), faculty (All Students) and college (Medical Students), and submit the first Semester CRF.</li>
                      <li>Visit the academic affairs and submit all the first Semester CRF</li>
                    </ol>
                  </div>
                  <div class="font-weight-bold font-italic my-3">All payments must be made on the portal. Failure to register at the stipulated time or adhere to the guidelines would attract a penalty of 10,000 NGN
                    Should you encounter any technical difficulties, please send them to mailto:help@fulafia.edu.ng and expect reply/resolution minimum 48hrs.</div>
                  <div class="font-weight-bold">Second semester</div>
                  <div >
                    <ol>
                      <li>Login to the Portal</li>
                      <li>Login with the Matric Number and password</li>
                      <li>Go to the dashboard, click on Course Registration and then on “Semester” then “Second” to register for Second Semester Courses</li>
                      <li>Register for your courses</li>
                      <li>Take the printed course registration form to your Level Adviser for endorsement.</li>
                      <li>Visit your department (All Students), faculty (All Students) and college (Medical Students), and submit the second Semester CRF</li>
                      <li>Visit the academic affairs and submit all the second Semester CRF.</li>
                    </ol>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="" expand-icon="mdi-menu-down">
                <div class="font-weight-bold">Announcement on Matriculation </div>
              </v-expansion-panel-header>
              <v-divider class="my-1"></v-divider>
              <v-expansion-panel-content class="">
                <div style="font-size: 10pt">
                  <p>The matriculation and orientation ceremonies of new students as scheduled in the reviewed time-table has been postponed indefinitely</p>
                  <p>The new 100level students admitted 2022/2023 (those that wrote jamb in 2022 for which their admission is being processed now) can not be on campus now but can continue their registration and process payments on-line</p>
                  <p>Accordingly, resumption date for 2021/2022 is 17th April, 2023 and 2022/2023 set is to be announced</p>

                  <div class="mt-4 font-weight-bold">Signed:</div>
                  <div class=" mb-4 font-weight-bold font-italic">Management</div>
                  <p>Following the announcement made by the University asking the 100-Level students of 2022/2023 session to continue with their registration and process payments online, management wishes to make further clarification as follows:</p>
                  <p>The decision of the University was made to reduce traffic in the process of registration of two sets of students concurrently. </p>
                  <p>Therefore, based on the decision made by the University, students of 2021/2022 session will now have to complete their registration and resume classes while their counterparts of the 2022/2023 session will be engaged in an online registration process and classes.</p>
                  <p>Those among the 2022/2023 set  who are presently in Lafia and have secured their accommodation, an arrangement has been made to take care of their online classes. Modalities for online classes for the first semester will be communicated to the affected students soon.</p>
                  <p>It shall be noted that the students (2022/2023 set) will resume on campus at the end of May 2023 and be merged in the second semester with their 2021/2022 colleagues to continue with their studies concurrently.</p>
                  <p>Consequently, orientation and matriculation exercises for both sets of students will, also, hold at the same time.</p>
                  <p>It should be noted that Federal University of Lafia is reputed for its meticulous and diligent way of doing things, a situation which has always turned out to be in the overall interest of the University community.</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <x-card-action>
          <x-button outlined text color="error" class="d-flex ml-auto" @click="show = !show">Close</x-button>
        </x-card-action>
      </x-card>
    </x-dialog>
  </transition>
</template>

<script>
import XDialog from "./XDialog.vue";
import XCard from "./XCard.vue";
import XCardTitle from "./XCardTitle.vue";
import XButton from "../widgets/XButton.vue";
import XCardAction from "./XCardAction.vue";

export default {
  name: "Announcement",
  components: {XCardAction, XButton, XCardTitle, XCard, XDialog},
  data: () => ({
    show: true
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.xs;
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  .v-card {
    position: absolute;
    justify-items: start;
    top: 0;
    right: 0;
    height: 100vh;
    width: 480px;
    max-width: 480px;
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;


    @media screen and (max-width: 480px) and (orientation: portrait) {
      position: sticky;
      left: 0;
      bottom: 0;
      top: 5em;
      width: 480px;
      max-width: 480px;
      height: 100vh;
      min-height: 0;
      scroll-behavior: smooth;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none;
    }
  }
}
</style>
